<template>
  <div class="reservation-checkout-receipt" data-testid="reservation-checkout-receipt">
    <div class="flex flex-col" style="gap: 4px">
      <div class="row" @click="expand = !expand">
        <div class="flex items-center">
          <span>結帳總金額</span>
          <span class="material-icons">{{ expand ? 'expand_less' : 'expand_more' }} </span>
        </div>
        <span>$ {{ displayData.totalItemsPrice }}</span>
      </div>

      <div v-if="expand" class="flex flex-col" style="gap: 6px">
        <div
          v-for="(group, idx) in displayData.groupItems"
          :key="`group-${idx}`"
          :data-testid="`detail_group_${idx}`"
          class="sub-row"
        >
          <div v-for="(item, idx) in group" :key="`item-${idx}`" class="row">
            <span>{{ item.name }}</span>
            <span>$ {{ item.price }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="displayData.totalClassTicketDiscount !== '0'" class="row">
      <span>堂票折抵</span>
      <span>-$ {{ displayData.totalClassTicketDiscount }}</span>
    </div>
    <div v-if="displayData.totalCashbackDiscount !== '0'" class="row">
      <span>回饋金折抵</span>
      <span>-$ {{ displayData.totalCashbackDiscount }}</span>
    </div>
    <!-- <p>折扣碼折抵</p> -->
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { map, toNumber, reduce } from 'lodash'

export default defineComponent({
  name: 'ReservationCheckoutReceipt',
  props: {
    data: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const expand = ref(true)
    const totalTypingDiscount = (typing) => {
      return reduce(props.data.discounts, (acc, discount) => {
        if (discount.type === typing) {
          return acc + discount.total
        }
        return acc
      }, 0)
    }

    const groupItem = () => {
      // mockData.items 根據 groupId 分組
      return reduce(props.data.items, (acc, item) => {
        if (!acc[item.groupId]) {
          acc[item.groupId] = []
        }
        acc[item.groupId].push(item)
        return acc
      }, {})
    }

    const groupItemDetail = (groupItems) => {
      // return [{name: item.name, price: item.total }, ...]
      // type 依照  appointmentService, appointmentSubService,appointmentAttachService 的順序排序
      return groupItems.sort((a, b) => {
        const typeOrder = ['appointmentService', 'appointmentSubService', 'appointmentAttachService']
        return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type)
      }).map((item) => {
        return {
          name: item.name,
          price: item.total,
        }
      })
    }

    const displayData = computed(() => {
      const groups = groupItem()
      const items = map(groups, (group) => groupItemDetail(group))

      // totalClassTicketDiscount, add all discounts from classTicketRecord type
      const totalClassTicketDiscount = totalTypingDiscount('classTicketRecord')
      // totalCashbackDiscount, add all discounts from cashback type
      const totalCashbackDiscount = totalTypingDiscount('cashback')

      return {
        totalClassTicketDiscount: toNumber(totalClassTicketDiscount).toLocaleString(),
        totalCashbackDiscount: toNumber(totalCashbackDiscount).toLocaleString(),
        totalItemsPrice: toNumber(props.data.totalItemsPrice).toLocaleString(),
        groupItems: items,
      }
    })
    return { displayData, expand }
  },
})
</script>

<style lang="postcss" scoped>
.reservation-checkout-receipt {
  @apply flex flex-col gap-[12px] pt-[4px];
}

.row {
  @apply flex justify-between;
  @apply leading-[20.27px];
}

.sub-row {
  @apply text-gray-60;
  @apply flex flex-col pl-[8px] ;
  @apply leading-[20.27px];
}

</style>
